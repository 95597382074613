<template>
  <div class="all">
    <div class="all-bg"></div>
    <div class="topTitle">
      <div class="title">
        <div class="imgLogo"><img class="home" src="../assets/img/logo2.png">
          <p>环宇建筑科技股份有限公司</p>
        </div>
        <div class="tab_but">
          <router-link :to="{name:'Home'}" class="but_tab"> 网站首页</router-link>
          <router-link :to="{name:'Recruiting'}" class="but_tab"> 招标公告</router-link>
          <router-link :to="{name:'Winning'}" class="but_tab"> 中标公示</router-link>
          <router-link :to="{name:'Service'}" class="but_tab"> 企业服务</router-link>
          <router-link :to="{name:'Help'}" class="but_tab_help" style=""> 帮助中心</router-link>
          <div class="but_tab" @click="exitBut" v-if="id !== null">退出登录</div>
        </div>
      </div>
      <!--    tab切换-->
      <div class="tabs_Help">
        <div class="tabs_r">
          <!--          左侧切换tabs-->
          <div v-for="(item,i) in hder" :key="i" :class="{tab_textBut:i===index}" class="tab_text"
               @click="msg_but(i)">{{ hder[i].subject }}
          </div>
        </div>
        <div  class="tabs_l">
          <img src="../assets/img/help/help1.png">
          <div class="text_tabs">
            <p>{{helpList.subject}}</p>
            <div class="content_tab">
              <p v-html="helpList.content"></p>
            </div>
            <div class="file_text">
              <div v-for="(item,index) in fileList" :key="index" class="xian" >
                <div class="text_wd">{{ item.fileName }}</div>
                <p>{{ item.size }} </p>
                <p>{{ item.createDate }}</p>
<!--                <a :href="'http://112.15.94.215:8088/seeyon/eu.do?method=dowloadFile&fileId=' + item.fileUrl" download="'http://112.15.94.215:8088/seeyon/eu.do?method=dowloadFile&fileId=' + item.fileUrl">下载</a>-->
<!--                <a :href="'http://120.27.145.210/api' + item.fileUrl" download="'http://120.27.145.210/api' + item.fileUrl">下载</a>-->
                <a :href="'https://www.zjhy.cc/api' + item.fileUrl"  >下载</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="cont"></div>
    <!--    底部-->
    <div class="bottom_d">
      <div class="logo_d">
        <img src="../assets/img/back.png">
        <p>
          地址：浙江省绍兴市凤林西路300号环宇大厦<br>
          电话：0575-85132445 85336908<br>
          传真：0575-85117404 85336900<br>
          <a href="https://beian.miit.gov.cn/#/Integrated/index" style="color: #FFFFFF">备案：浙ICP备11004969号-1</a>
        </p>
      </div>
      <div class="wx_logo">
        <img src="../assets/img/logo3.png">
        <p>
          微信公众号：hyjzkj
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { exitLogin, RegRedTerms } from '@/api/api'
import axios from 'axios'

export default {
  name: 'service',
  data () {
    return {
      hder: [],
      helpList: [], // 注册条款
      fileList: [],
      id: '',
      msg: true,
      index: 0
    }
  },
  created () {
    this.RegTerms()
    this.id = localStorage.getItem('EUJSESSIONID')
  },
  methods: {
    // 退出登录
    exitBut () {
      exitLogin({
        method: 'logout'
      }, res => {
        this.$message.info('已注销用户！')
        this.$router.replace('/login/login')
        this.$cookies.remove('token', 'EUJSESSIONID=egCR0M2aKCuk46H9fucdWyPBsqeZTFGnU%2Fylt4meBeE%3D')
        localStorage.removeItem('EUJSESSIONID')
      })
    },
    home () {
      this.$router.replace('/')
    },
    msg_but (i) {
      this.index = i
      this.helpList = this.hder[i]
      this.fileList = this.helpList.atts
    },
    RegTerms () {
      axios.defaults.headers.token = localStorage.getItem('token')
      RegRedTerms({
        method: 'about',
        frName: ''
      }, res => {
        // console.log(res)
        this.hder = res.data.data
        this.helpList = this.hder[0]
        this.fileList = this.helpList.atts
      })
    },
    FileLoad (item) {
      axios.get('http://112.15.94.215:8088/seeyon/eu.do?method=dowloadFile&fileId=' + item.fileUrl)
    }
  }
}
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  text-decoration: none;
}

/*全部*/
.all {
  position: relative;
  width: 100%;
  /*height: 1200px;*/
  margin: 0 auto;
  background-color: #eeeeee;
}
.home{
  cursor: pointer;
}
.imgLogo >p{
  color: #FFFFFF;
  line-height: 10px;
  padding-left: 67px;
  letter-spacing: 1px;
  font-size: 23.2px;
  cursor: pointer;
  font-weight: bold;
}
/*顶部title*/
.topTitle {
  width: 100%;
  /*height: 360px;*/
  /*opacity:0.5;*/
  /*filter:alpha(opacity=50);*/
  /*background-color: rgba(255, 255, 255, 0.2);*/
  /*background-image: url("../assets/img/help/help.png");*/
  /*background-color: #FFFFFF;*/
  background-size: 100% 100%;
}

.all-bg {
  position: absolute;
  top: 0;
  /*z-index: -9999999;*/
  width: 100%;
  height: 360px;
  background-color: #ffffff;
  background-image: url("../assets/img/help/help.png");
  background-size: 100% 100%;
}

.title {
  display: flex;
  justify-content: space-around;
  width: 100%;
  height: 90px;
  background-color: black;
  opacity: 0.68;
}

.imgLogo > img {
  width: 359px;
  height: 54px;
  padding-top: 10px;
}

.tab_but {
  display: flex;
  width: 600px;
  height: 90px;
  color: #ffffff;
  padding-top: 20px;
  text-align: center;
}

.but_tab {
  font-size: 16px;
  width: 220px;
  height: 40px;
  line-height: 40px;
  color: white;
  margin:0 10px;
  text-decoration: none;
}

.but_tab_help {
  font-size: 16px;
  width: 220px;
  height: 40px;
  color: #10539B;
  background-color: white;
  line-height: 40px;
  cursor: pointer;
  text-decoration: none;
}

.but_tab:hover {
  width: 220px;
  height: 40px;
  color: #10539B;
  background-color: white;
  cursor: pointer;
}

/*中间占位*/
.cont {
  position: relative;
  background-color: #10539B;
  z-index: -100;
  width: 100%;
  /*height: 680px;*/
}

/*底部*/
.bottom_d {
  display: flex;
  justify-content: space-around;
  width: 100%;
  height: 360px;
  background-color: #191919;
  text-align: left;
  margin: 0px auto;
}

.logo_d {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 320px;
  height: 250px;
  margin-top: 90px;
  color: white;
  line-height: 30px;
}

.logo_d > img {
  width: 300px;
  height: 90px;
  margin-left: -28px;
  margin-top: 38px;
}

.wx_logo {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 320px;
  height: 190px;
  /*background-color: #554ace;*/
  margin-top: 90px;
  color: white;
  line-height: 30px;
}

.wx_logo > img {
  width: 142px;
  height: 142px;
}

/*tabs切换*/
.tabs_Help {
  display: flex;
  justify-content: space-around;
  width: 85%;
  /*height: 850px;*/
  margin: 50px auto;
  opacity: 0.99;

}
.tabs_Help:hover{

}
.tab_textBut {
  width: 100%;
  /*height: 10%;*/
  background-color: #10539B;
  font-size: 16px;
  color: white;
  text-align: center;
  line-height: 80px;
  letter-spacing: 1px;
  border-bottom: 1px solid #EEEEEE;
  cursor: pointer;
}
/*左侧*/
.tabs_r {
  width: 20%;
  min-height: 850px;
  background-color: #ffffff;
}

/*My and messg*/
.tab_text {
  width: 100%;
  /*height: 10%;*/
  /*background-color: #10539B;*/
  font-size: 16px;
  /*color: black;*/
  text-align: center;
  line-height: 80px;
  letter-spacing: 1px;
  border-bottom: 1px solid #EEEEEE;
  cursor: pointer;
}

/*右侧*/
.tabs_l {
  width: 70%;
  /*height: 850px;*/
  min-height: 850px;
  background-color: #ffffff;
}
.tabs_l>img{
  width: 100%;
}
/*tabs_中的文字*/
.text_tabs{
  width: 90%;
  /*height: 65%;*/
  /*background-color: #10539B;*/
  margin: 20px auto;
}
.text_tabs>p{
  font-size: 16px;
  color: #333333;
  font-weight: bold;
}
.content_tab{
  width: 567px;
  /*height: 224px;*/
  color: #666666;
  margin-top: 50px;
  line-height: 40px;
  font-size: 14px;
}
.file_text{
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 200px;
  font-size: 14px;
  color: #666666;
  text-align: center;
}
.xian{
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom:1px solid #EEEEEE;
  line-height: 40px;
  /*border: 2px solid red;*/
  width: 650px;
  height: 40px;
  margin-top: 10px;
}
.text_wd{
  text-align: left;
  width: 360px;
}
</style>
